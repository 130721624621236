/* eslint-disable react/jsx-props-no-spreading */
import { IventisErrorBoundaryWithToast } from "@iventis/error-boundaries";
import { toast } from "@iventis/toasts/src/toast";
import { FunctionalityContext } from "@iventis/utilities";
import React from "react";
import { useSelector } from "react-redux";
import { OmitNative } from "react-router";
import { Route, RouteProps } from "react-router-dom";
import { UpgradeSubscriptionAlert, useUpgradePlanAlert } from "@iventis/permissions/src/upgrade-subscription-alert";
import { AuthState } from "@iventis/permissions/src/auth.slice";

/**
 * Used in place of the react router dom <Route /> so we can include our globally accessible modals which require the history object from the router
 */
export const IventisRoute = <T extends {} = {}, Path extends string = string>(props: RouteProps<Path> & OmitNative<T, keyof RouteProps>) => {
    // eslint-disable-next-line react/destructuring-assignment
    const Page = props.component;
    return <Route {...props} component={undefined} render={(p) => <PageWithFunctionality renderProps={p} routeProps={props} Page={Page} />} />;
};

const PageWithFunctionality = ({ routeProps, renderProps, Page }) => {
    const upgradePlan = useUpgradePlanAlert();
    const functionality = useSelector((s: { auth: AuthState }) => s.auth.subscriptionPlan?.functionality);

    return (
        <IventisErrorBoundaryWithToast toast={toast}>
            <FunctionalityContext.Provider value={{ ...functionality, open: upgradePlan.open }}>
                {Page == null ? (
                    <>
                        {routeProps.render(renderProps)}
                        <UpgradeSubscriptionAlert />
                    </>
                ) : (
                    <>
                        <Page {...renderProps} />
                        <UpgradeSubscriptionAlert />
                    </>
                )}
            </FunctionalityContext.Provider>
        </IventisErrorBoundaryWithToast>
    );
};
