import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { MapLayerCreateRequestDto } from "@iventis/domain-model/model/mapLayerCreateRequestDto";
import { DomainLayer } from "@iventis/map-engine/src/state/map.state.types";
import { mappingApi } from "@iventis/api/src/api";
import { LayersCopyApiRequest } from "@iventis/domain-model/model/layersCopyApiRequest";
import { LayersImportApiRequest } from "@iventis/domain-model/model/layersImportApiRequest";
import { AxiosResponse } from "axios";
import { Content } from "@iventis/translations";
import { translate } from "@iventis/translations/translation";
import { getNextSidebarOrderValue } from "../sidebar-order-helpers";
import { MapLayerPatchType } from "../state/map.slice.thunks";

export const postLayer = async (layer: MapLayer, existingLayers: DomainLayer[], options = { keepOrder: false }) => {
    const newSidebarOrder = options.keepOrder ? layer.sidebarOrder : getNextSidebarOrderValue(existingLayers);
    const mapLayerCreateRequest: MapLayerCreateRequestDto = {
        id: layer.id,
        sidebarOrder: newSidebarOrder,
        mapOrder: layer.mapOrder,
        name: layer.name,
        styleType: layer.styleType,
        areaStyle: layer.areaStyle,
        lineStyle: layer.lineStyle,
        pointStyle: layer.pointStyle,
        iconStyle: layer.iconStyle,
        modelStyle: layer.modelStyle,
        lineModelStyle: layer.lineModelStyle,
        tooltipDataFieldIds: layer.tooltipDataFieldIds,
        groupId: layer.groupId,
    };
    const response = await mappingApi.post<MapLayer, AxiosResponse<MapLayer>, MapLayerCreateRequestDto>(`/maps/${layer.mapId}/layers`, mapLayerCreateRequest, {
        customErrorMessage: {
            title: translate(Content.errors.custom_network_error.create.title, { entity: translate(Content.map9.layer) }),
            message: translate(Content.errors.custom_network_error.create.message, { entity: translate(Content.map9.layer) }),
        },
    });
    return response.data;
};

/** Imports the given layers into the given map */
export const importLayers = (mapId: string, imports: LayersImportApiRequest) =>
    mappingApi.post(`/maps/${mapId}/layers/import`, imports, {
        customErrorMessage: {
            title: translate(Content.errors.custom_network_error.import.title, { entity: translate(Content.map9.layers) }),
            message: translate(Content.errors.custom_network_error.import.message, { entity: translate(Content.map9.layers) }),
        },
    });

/** Copy or Unlink the given layers */
export const copyLayers = (mapId: string, request: LayersCopyApiRequest) =>
    mappingApi.post<unknown, unknown, LayersCopyApiRequest>(`/maps/${mapId}/layers/copy`, request, {
        customErrorMessage: {
            title: translate(Content.errors.custom_network_error.update.title, { entity: translate(Content.map9.layers) }),
            message: translate(Content.errors.custom_network_error.update.message, { entity: translate(Content.map9.layers) }),
        },
    });

export const patchLayers = (mapId: string, mapLayers: MapLayerPatchType[]) =>
    mappingApi.patch(`/maps/${mapId}/layers`, mapLayers, {
        customErrorMessage: {
            title: translate(Content.errors.custom_network_error.update.title, { entity: translate(Content.map9.layers) }),
            message: translate(Content.errors.custom_network_error.update.message, { entity: translate(Content.map9.layers) }),
        },
    });
