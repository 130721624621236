import { Content } from "@iventis/translations/content/typed-content";
import { customMediaQueryMax, customMediaQueryMin, formPadding, screenSizeBreakpoints, styled } from "@iventis/styles";
import { css, Theme } from "@emotion/react";
import { Header1, Header5 } from "@iventis/styles/src/components/texts";
import { ApplicationName } from "@iventis/domain-model/model/applicationName";
import { borderRadius, fontSizes, sectionalMargin } from "@iventis/styles/src/atomic-rules";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IVENTIS_LOGO } from "@iventis/utilities";
import { format } from "date-fns";
import { LoadingComponent } from "@iventis/components";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { LoadingEvent } from "@iventis/types/loading.types";
import { getProjects, removeProject, getProjectBillingData } from "@iventis/permissions/src/auth.slice";
import { IventisErrorBoundaryWithToast } from "@iventis/error-boundaries";
import { toast } from "@iventis/toasts/src/toast";
import { RootDispatch, RootState } from "@iventis/plans/src/state/root.store";
import { iventisApi, libraryApi, permissionsApi, getAppUrlForSubdomain, getApiBaseUrlSpeficicSubdomain } from "@iventis/api/src/api";
import { Project } from "@iventis/domain-model/model/project";
import { Node } from "@iventis/domain-model/model/node";
import { MicroService } from "@iventis/api-helpers";
import ProjectCard from "./project-card";
import { applicationDetails } from "./AppDetails";

const HomePage: React.FC = () => {
    const dispatch = useDispatch<RootDispatch>();
    const translate = useIventisTranslate();

    const { firstName } = useSelector((state: RootState) => state.auth.user);

    useEffect(() => {
        dispatch(getProjects({}));
    }, []);

    return (
        <StyledPage>
            <div className="margin" />
            <div className="page-content">
                <StyledTitleSection>
                    <Header1>{translate(Content.projects.welcome_title, { name: firstName })}</Header1>
                    <div className="subtitle-container">
                        <Header5 className="subtitle">{translate(Content.projects.welcome_subtitle)}</Header5>
                        <Header5 className="date">{format(new Date(), "d MMMM yyyy")}</Header5>
                    </div>
                    <IventisErrorBoundaryWithToast toast={toast}>
                        <ProjectCards />
                    </IventisErrorBoundaryWithToast>
                </StyledTitleSection>
            </div>
            <div className="margin" />
        </StyledPage>
    );
};

const ProjectCards: FunctionComponent = () => {
    const dispatch = useDispatch<RootDispatch>();

    const { projects, waitingFor } = useSelector((state: RootState) => state.auth);

    const { loadingProjectId } = useSelector((state: RootState) => state.auth);
    const [billingPopoverProjectId, setBillingPopoverProjectId] = useState<string>(null);
    const [serviceUnavailablePopover, setServiceUnavailablePopover] = useState<string>(null);

    const [loggingIntoProject, setLogingIntoProject] = useState<string>(null);

    const loadingSpinnerProject = loadingProjectId || loggingIntoProject;

    const onProjectSelected = async (project: Project) => {
        setLogingIntoProject(project.id);
        try {
            // Attempt to login into project
            const { data } = await permissionsApi.get<string>(`authentication/access-token`);
            await iventisApi.put(`${getApiBaseUrlSpeficicSubdomain(MicroService.PERMISSIONS, project.shortName)}authentication/project/${project.id}?jwt=${data}`, {
                suppress401: true,
            });
            redirectToProject(project);
        } catch (err) {
            const errorCode = err?.response?.status;

            if (errorCode === 402) {
                // Show the popover over the selected project
                setBillingPopoverProjectId(project.id);
                dispatch(removeProject());
                // eslint-disable-next-line dot-notation
            } else if (errorCode === 503) {
                // Show the popover over the selected project
                setServiceUnavailablePopover(project.id);
                dispatch(removeProject());
                dispatch(getProjectBillingData(project.id));
            } else if (errorCode === 401) {
                // When 401 is returned, the user is not authorised to access the project with thier current logged method.
                // This occurs when the project is SSO enabled, the user is SSO enabled but the user is logged in using a email and password.
                // In this case they are required to log in using SSO, so should be re-directed to login screen of the project they wish to login to.
                const projectUrl = `${getAppUrlForSubdomain(project.shortName)}`;
                window.location.replace(projectUrl);
            }
        }
        setLogingIntoProject(null);
    };

    const redirectToProject = async (project: Project) => {
        const { data: projectApplicationNodes } = await libraryApi.get<Node[]>(`projects/${project.id}/applications`);

        // Attempt to work out which app to direct too on selected project
        const projectApplications = applicationDetails.filter((appDetail) => projectApplicationNodes.find((a) => a.name === appDetail.appName));
        if (projectApplications.some((app) => app.appName === ApplicationName.Maps.toString())) {
            projectApplications.unshift(applicationDetails.find((app) => app.appName === ApplicationName.Dashboard.toString()));
        }
        if (projectApplications.length === 0) {
            // eslint-disable-next-line no-console
            console.warn("Project has no applications");
        }
        const firstProjectApplicationUrl = projectApplications[0]?.url || "";

        // Re-direct user to project shortname
        const projectUrl = `${getAppUrlForSubdomain(project.shortName)}/${firstProjectApplicationUrl}`;
        window.location.replace(projectUrl);
    };
    return (
        <StyledProjectsContainer numberItems={projects?.length}>
            {waitingFor.some((e) => e.eventName === LoadingEvent.GET_PROJECTS) ? (
                <LoadingComponent />
            ) : (
                projects.map((project) => (
                    <StyledProjectCardErrorBoundary key={project.id} size="overlay" toast={toast}>
                        <StyledProjectCard
                            disabled={Boolean(loadingSpinnerProject) && loadingSpinnerProject !== project.id && loggingIntoProject !== project.id}
                            project={project}
                            imageUrl={project.imageUrl || IVENTIS_LOGO}
                            loadingProject={loggingIntoProject === project.id || loadingProjectId === project.id}
                            key={project.id}
                            onSelected={onProjectSelected}
                            showBillingPopover={billingPopoverProjectId === project.id}
                            showServiceUnavailablePopover={serviceUnavailablePopover === project.id}
                        />
                    </StyledProjectCardErrorBoundary>
                ))
            )}
        </StyledProjectsContainer>
    );
};

const ProjectCardStyle = css`
    border-radius: ${borderRadius.double};
    min-height: 300px;
    max-width: 484px;

    ${customMediaQueryMax(screenSizeBreakpoints.extraSmall)} {
        width: 100%;
        max-width: 100%;
    }
`;

const StyledProjectCardErrorBoundary = styled(IventisErrorBoundaryWithToast)`
    border-radius: ${borderRadius.double};
    ${ProjectCardStyle}
`;

const StyledProjectCard = styled(ProjectCard)`
    ${ProjectCardStyle}
`;

const StyledPage = styled.div`
    display: flex;
    justify-content: stretch;
    height: 100%;
    overflow-y: auto;

    background-image: linear-gradient(to top, #f2f5fa 27%, #fff 91%);

    .margin {
        flex-grow: 1;
    }

    .page-content {
        flex-grow: 2;
        max-width: 1400px;
    }
    padding: ${formPadding};
`;

const StyledProjectsContainer = styled.section<{ numberItems: number }>`
    display: grid;
    row-gap: 30px;
    column-gap: 30px;
    padding-bottom: 10vh;

    ${customMediaQueryMax(screenSizeBreakpoints.extraSmall)} {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    ${customMediaQueryMin(screenSizeBreakpoints.extraSmall)} {
        ${customMediaQueryMax(731)} {
            column-gap: 16px;
            row-gap: 16px;
        }

        ${customMediaQueryMax(screenSizeBreakpoints.extraLarge)} {
            grid-template-columns: repeat(2, calc(50% - 15px));
            //grid-template-rows: repeat(300px, ceil(calc(numberItems / 2)));
        }
    }

    ${customMediaQueryMin(screenSizeBreakpoints.extraLarge)} {
        grid-template-columns: repeat(3, calc(33.33333% - 20px));
        //grid-template-rows: repeat(300px, ceil(calc(numberItems / 3)));
    }
`;

const StyledTitleSection = styled.section`
    h1 {
        font-weight: 300;
        padding-top: 32px;
        padding-bottom: 32px;
        color: ${({ theme }: { theme: Theme }) => theme.typographyColors.core};
    }

    .subtitle-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: ${sectionalMargin};
        font-weight: 500;

        .subtitle {
            text-transform: uppercase;
            font-size: ${fontSizes.xSmall};
            letter-spacing: 1.2px;
            color: ${({ theme }: { theme: Theme }) => theme.typographyColors.core};
        }

        .date {
            font-size: ${fontSizes.small};
            color: ${({ theme }: { theme: Theme }) => theme.typographyColors.subdued};
        }
    }
`;

export default HomePage;
