import { IRouteWaypointService } from "@iventis/map-engine";
import { RouteWaypoint } from "@iventis/domain-model/model/routeWaypoint";
import { mappingApi } from "@iventis/api/src/api";
import { translate } from "@iventis/translations/translation";
import { Content } from "@iventis/translations";

/**
 * Api calls needed to edit route waypoints and generate routes in the main application
 */
export const routeWaypointService: IRouteWaypointService = {
    getRouteWaypoints: async (mapObjectId: string, mapId: string) => {
        const { data } = await mappingApi.get<RouteWaypoint[]>(`/maps/${mapId}/map-objects/${mapObjectId}/route-waypoints`);
        return data;
    },
    putRouteWaypoints: async (mapObjectId: string, mapId: string, routeWaypoints: RouteWaypoint[]) => {
        if (routeWaypoints.length < 2) {
            throw new Error("Route waypoints must have at least two waypoints");
        }

        const { data } = await mappingApi.put<RouteWaypoint[]>(`/maps/${mapId}/map-objects/${mapObjectId}/route-waypoints`, routeWaypoints, {
            customErrorMessage: {
                title: translate(Content.errors.custom_network_error.update.title, { entity: translate(Content.map9.route_waypoints) }),
                message: translate(Content.errors.custom_network_error.update.message, { entity: translate(Content.map9.route_waypoints) }),
            },
        });
        return data;
    },
};
