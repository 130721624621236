import { DataField } from "@iventis/domain-model/model/dataField";
import { mappingApi } from "@iventis/api/src/api";
import { Content } from "@iventis/translations";
import { translate } from "@iventis/translations/translation";

/** Sends a post request for a layer data field */
export const postLayerDataField = async (request: { layerId: string; dataField: DataField }) => {
    const response = await mappingApi.post<DataField>(`/layers/${request.layerId}/data-fields`, request.dataField, {
        customErrorMessage: {
            title: translate(Content.errors.custom_network_error.create.title, { entity: translate(Content.map9.layer_data_field) }),
            message: translate(Content.errors.custom_network_error.create.message, { entity: translate(Content.map9.layer_data_field) }),
        },
    });
    return response?.data;
};

/** Sends a patch request for a layer data field */
export const patchLayerDataField = async (request: { layerId: string; dataFieldPatch: Partial<DataField>; datafieldId: string }) => {
    const response = await mappingApi.patch<DataField>(`/layers/${request.layerId}/data-fields/${request.datafieldId}`, request.dataFieldPatch, {
        customErrorMessage: {
            title: translate(Content.errors.custom_network_error.update.title, { entity: translate(Content.map9.layer_data_field) }),
            message: translate(Content.errors.custom_network_error.update.message, { entity: translate(Content.map9.layer_data_field) }),
        },
    });
    return response?.data;
};

/** Sends a delete request of a layer data field */
export const deleteLayerDataField = async (request: { layerId: string; dataFieldId: string }) => {
    const response = await mappingApi.delete<string>(`/layers/${request.layerId}/data-fields/${request.dataFieldId}`, {
        customErrorMessage: {
            title: translate(Content.errors.custom_network_error.delete.title, { entity: translate(Content.map9.layer_data_field) }),
            message: translate(Content.errors.custom_network_error.delete.message, { entity: translate(Content.map9.layer_data_field) }),
        },
    });
    return response?.data;
};
