import React, { useState } from "react";
import { main, dark, muiTheme, GlobalStyle, styled } from "@iventis/styles";
import { MicroService } from "@iventis/api-helpers";
import { Provider } from "react-redux";
import { PaletteMode, ThemeProvider } from "@mui/material";
import { Global, Theme } from "@emotion/react";
import t from "@iventis/translations";
import { GlobalToastRef, toast } from "@iventis/toasts/src/toast";
import "@iventis/styles/src/fonts/index.css";
import "@iventis/styles/src/font-awesome";
import "@iventis/schedule/src/session-card-z-index.css";
import "wicg-inert";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import i18n from "@iventis/translations/i18n";
import { checkForKeyboardUser } from "@iventis/utilities";
import { TranslationsComponent } from "@iventis/translations/translation";
import { ErrorBoundaryContext, ErrorBoundaryToastContext, IventisErrorBoundary } from "@iventis/error-boundaries";
import { rootStore } from "@iventis/plans/src/state/root.store";
import { getIventisEnvironmentData } from "@iventis/api/src/api";
import { ObservabilityAndMonitoringConfig, initialiseObservabilityAndMonitoring } from "@iventis/observability-and-monitoring";
import { ToastProvider } from "@iventis/toasts/src/toast-provider";
import ErrorStackParser from "error-stack-parser";
import axoisInterceptor from "./axios-interceptor";
import PublicRouter from "./public.router";
import "./assets/images/favicon.ico";
import AwsRum from "./aws-rum-init";

const awsRum = AwsRum;

const { languages } = t;

const environment = getIventisEnvironmentData();

let config: ObservabilityAndMonitoringConfig = { errorTrackingEnabled: false, replayEnabled: false, usageMetricsEnabled: true };
if (environment.enableFrontendObservabilityAndMonitoring) {
    // For Saudi Arabia region we don't want to have replays enabled
    const isSARegion = window.location.hostname.toLowerCase().endsWith("iventis.sa.com");
    config = { errorTrackingEnabled: true, replayEnabled: !isSARegion, usageMetricsEnabled: true };
}

initialiseObservabilityAndMonitoring(config, environment.appUrl);

Object.values(MicroService).forEach((microservice) => {
    axoisInterceptor.interceptor(rootStore, microservice);
});

// fetch user theme
const selectedTheme: Theme = undefined;

// Create a client
const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

// added example <section> to show data-table
export const App: React.FC = () => {
    const [darkMode] = useState(false);
    const theme: Theme = darkMode ? dark : selectedTheme || main;
    const mode: PaletteMode = darkMode ? "dark" : "light";

    i18n.loadLanguages(languages).then(() => {
        document.body.dir = i18n.dir();
    });

    checkForKeyboardUser();
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <Provider store={rootStore}>
                    <ThemeProvider theme={muiTheme(theme, mode, i18n.dir())}>
                        <ToastProvider>
                            <ErrorBoundaryContext.Provider
                                value={{
                                    onError: (errorCode, error) => {
                                        // When manually recording errors with rum, lineno/colno etc are not automatically recorded so we need to parse the error stack ourselves
                                        const parsed = ErrorStackParser.parse(error);
                                        awsRum?.recordError({
                                            message: `${errorCode}: "${error.message}" at ${error[0]?.functionName}`,
                                            lineno: parsed[0]?.lineNumber,
                                            colno: parsed[0]?.columnNumber,
                                            filename: parsed[0]?.fileName,
                                            stack: error?.stack,
                                        });
                                    },
                                }}
                            >
                                <ErrorBoundaryToastContext.Provider value={{ toast }}>
                                    <AppErrorBoundary>
                                        <PublicRouter />
                                        <TranslationsComponent />
                                        <Global styles={GlobalStyle} />
                                        <GlobalToastRef />
                                    </AppErrorBoundary>
                                </ErrorBoundaryToastContext.Provider>
                            </ErrorBoundaryContext.Provider>
                        </ToastProvider>
                    </ThemeProvider>
                </Provider>
            </QueryClientProvider>
        </>
    );
};

const AppErrorBoundary = styled(IventisErrorBoundary)`
    width: auto;
`;
