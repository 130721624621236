import { useLocation } from "react-router";
import React, { FunctionComponent, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, MenuItem, Popover, Select, useTheme } from "@mui/material";
import t, { Content } from "@iventis/translations";
import { styled, Body1, Body3, HorizontalGap, iconButtonSize, inputHeight, muiInputFormsCSS } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InteractiveElement } from "@iventis/styles/src/components";
import { NavigationBarComponent, StyledItemSelect, UserThumbnail } from "@iventis/components";
import { API_KEY_QUERY_PARAM_KEY, PROJECT_ID_QUERY_PARAM_KEY, queryParams, useRefEffectValue } from "@iventis/utilities";
import { UserNotificationListComponent, isNotificationInProgress } from "@iventis/notifications";
import i18n from "@iventis/translations/i18n";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import { useProject } from "@iventis/permissions/src/use-project";
import { getProjects, logout } from "@iventis/permissions/src/auth.slice";
import { IventisErrorBoundaryWithToast } from "@iventis/error-boundaries";
import { toast } from "@iventis/toasts/src/toast";
import { RootDispatch, RootState } from "@iventis/plans/src/state/root.store";
import { getIventisEnvironmentData, getAppUrlForSubdomain } from "@iventis/api/src/api";
import { useNotifications } from "@iventis/api/src/notifications";
import { UserNotification } from "@iventis/domain-model/model/userNotification";
import { MetricName } from "@iventis/domain-model/model/metricName";
import { NotificationResourceData } from "@iventis/domain-model/model/notificationResourceData";
import { exportResourceDownload } from "@iventis/api/src/exports";
import { UserScope } from "@iventis/domain-model/model/userScope";
import { NotificationResourceType } from "@iventis/domain-model/model/notificationResourceType";
import { incrementMonitoringCount } from "@iventis/observability-and-monitoring";
import { UserIconGenerator } from "./user-icon-generator";

const { languages } = t;

const logoutApiUser = () => {
    const { subDomain } = getIventisEnvironmentData();
    const url = getAppUrlForSubdomain(subDomain);
    // eslint-disable-next-line iventis/no-href-assignments
    window.location.replace(url);
};

export interface NavBarComponentRef {
    pathChanged(): void;
    createNotification: (notification: UserNotification<NotificationResourceData>) => void;
    invalidateNotifications: () => void;
}

interface NavBarComponentProps {
    onLogoClicked: () => void;
}

declare const VERSION: string;

const NavBarComponent = forwardRef<NavBarComponentRef, NavBarComponentProps>(({ onLogoClicked: onLogoClickedCallback }, ref) => {
    const [popover, setPopover] = useState<"settings" | "help" | "notifications" | null>(null);
    const [popoverAnchor, setPopoverAnchor] = useState<Element>(null);
    const dispatch = useDispatch<RootDispatch>();
    const styledTheme: Theme = useTheme<Theme>();
    const translate = useIventisTranslate();
    const notificationButtonRef = useRef<HTMLButtonElement>();

    const { projects, user, currentMapId } = useSelector((s: RootState) => ({
        projects: s.auth.projects,
        user: s.auth.user,
        currentMapId: s.mapReducer.mapModule?.mapId,
        webSocketsStatus: s.connectionReducer.connectionStatus,
        mapId: s.mapReducer.mapModule?.mapId,
    }));

    const mapIdRef = useRefEffectValue(currentMapId);

    const project = useProject();

    const location = useLocation();

    const [useDefaultLogo, setUseDefaultLogo] = useState<boolean>();

    const onPathChanged = () => {
        const currentLocation = window.location.href;
        setUseDefaultLogo(currentLocation.endsWith("/projects"));
    };

    const toggleNotificationListVisibility = (open: boolean) => {
        if (open) {
            // Simulate a click event as the popover needs the onClick event to get the anchor element
            notificationButtonRef.current.click();
        } else {
            setPopover(null);
        }
    };

    const {
        notifications,
        isLoadingList: isLoadingNotificationsList,
        notificationsUnread,
        registerNotificationAsRead,
        refreshNotifications,
        createLocalNotification,
    } = useNotifications(
        user?.userId,
        project?.id,
        popover === "notifications",
        user?.userScope === UserScope.Application,
        toggleNotificationListVisibility,
        (notification) => {
            // If a notification has completed or failed, open the notification list
            if (!isNotificationInProgress(notification)) {
                toggleNotificationListVisibility(true);
            }
        },
        (notifications) => {
            const layersImportToMap = notifications.some(
                (notification) =>
                    (notification.resourceType === NotificationResourceType.LayersCopy || notification.resourceType === NotificationResourceType.LayersImport) &&
                    notification.resourceData.mapId === mapIdRef.current
            );

            // User is on the map page and the imported/copied layer belongs to the current map
            if (layersImportToMap && window.location.href.includes("map")) {
                toast.success({
                    title: translate(Content.map10.importFinished),
                    message: translate(Content.map8.importNoWebSocketsToast),
                    button: { label: translate(Content.map8.refresh) },
                    onClick: () => window.location.reload(),
                });
            }
        }
    );

    useImperativeHandle(ref, () => ({
        pathChanged: onPathChanged,
        createNotification: createLocalNotification,
        invalidateNotifications: refreshNotifications,
    }));

    useEffect(() => {
        onPathChanged();
    }, [location.pathname]);

    useEffect(() => {
        if (projects.length === 0 && queryParams[API_KEY_QUERY_PARAM_KEY] == null) {
            dispatch(getProjects({}));
        }
    }, []);

    const onLogoClicked = () => {
        onLogoClickedCallback();
    };

    const handleDownloadResource = (notificationId: string, assetId: string, assetName: string) => {
        // [BUG-15151] remove any . from the asset name as causing broken extension on download
        const safeAssetName = assetName.replace(/\./g, "");
        exportResourceDownload([assetId], safeAssetName);
        registerNotificationAsRead(notificationId);
    };

    const goToMap = async (mapId: string) => {
        const queryParams = new URLSearchParams(window.location.search);
        const apiKey = queryParams.get(API_KEY_QUERY_PARAM_KEY);
        const projectKey = queryParams.get(PROJECT_ID_QUERY_PARAM_KEY);
        const newSearch = apiKey && projectKey ? `?${API_KEY_QUERY_PARAM_KEY}=${apiKey}&${PROJECT_ID_QUERY_PARAM_KEY}=${project.id}` : "";
        window.location.assign(
            window.location.hostname === "localhost"
                ? `http://${window.location.hostname}:${window.location.port}/spatial-planner/map/${mapId}${newSearch}`
                : `https://${window.location.hostname}/spatial-planner/map/${mapId}${newSearch}`
        );
    };

    const logoUrl = useMemo(() => (useDefaultLogo ? undefined : project?.logoUrl), [useDefaultLogo, project?.logoUrl]);

    const hasNotification = notificationsUnread > 0;

    return (
        <NavigationBarComponent onLogoClicked={onLogoClicked} logoUrl={logoUrl}>
            <SubNavigation className="sub-nav">
                <InteractiveElement
                    type="button"
                    onClick={(e) => {
                        setPopoverAnchor(e.currentTarget);
                        setPopover("help");
                    }}
                    className="help"
                >
                    <span className="hidden">{translate(Content.settings3.help)}</span>
                    <FontAwesomeIcon size="xl" color={styledTheme.tertiaryColors.primary} icon="circle-question" />
                </InteractiveElement>
                {user.userScope === UserScope.Application && (
                    <InteractiveElement
                        ref={notificationButtonRef}
                        type="button"
                        onClick={(e) => {
                            incrementMonitoringCount({ name: MetricName.NOTIFICATIONS_VIEWED });
                            setPopoverAnchor(e.currentTarget);
                            setPopover("notifications");
                        }}
                        className="notifications"
                    >
                        <span className="hidden">{translate(Content.notifications.list.title)}</span>
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon size="xl" color={styledTheme.tertiaryColors.primary} icon="bell" />
                            {hasNotification && (
                                <FontAwesomeIcon
                                    width="10px"
                                    height="10px"
                                    stroke={styledTheme.secondaryColors.blank}
                                    strokeWidth="60"
                                    transform="up-8 right-14"
                                    color={styledTheme.primaryColors.focus}
                                    icon="circle"
                                />
                            )}
                        </span>
                    </InteractiveElement>
                )}
                <InteractiveElement
                    className="user-profile"
                    data-cy="user-profile-button"
                    style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}
                    onClick={(e) => {
                        setPopoverAnchor(e.currentTarget);
                        setPopover("settings");
                    }}
                >
                    <span className="hidden">{translate(Content.settings3.user_settings)}</span>
                    <StyledUser />
                </InteractiveElement>
            </SubNavigation>
            <StyledPopover
                open={popover === "settings"}
                anchorEl={popoverAnchor}
                onClose={() => setPopover(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <PopoverErrorBoundary toast={toast}>
                    <UserProfile />
                </PopoverErrorBoundary>
            </StyledPopover>
            <StyledPopover
                open={popover === "help"}
                anchorEl={popoverAnchor}
                onClose={() => setPopover(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <PopoverErrorBoundary toast={toast}>
                    <HelpAndResources />
                </PopoverErrorBoundary>
            </StyledPopover>
            <StyledPopover
                open={popover === "notifications"}
                anchorEl={popoverAnchor}
                onClose={() => setPopover(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <PopoverErrorBoundary toast={toast}>
                    <UserNotificationListComponent
                        notifications={notifications ?? []}
                        registerNotificationAsRead={registerNotificationAsRead}
                        onClose={() => setPopover(null)}
                        loading={isLoadingNotificationsList}
                        onRefreshNotifications={refreshNotifications}
                        notificationProps={{
                            [NotificationResourceType.MapExport]: { onDownloadClicked: handleDownloadResource },
                            [NotificationResourceType.LayersImport]: {
                                gotToMap: goToMap,
                                isUserCurrentlyInMap: (mapId) => currentMapId === mapId,
                            },
                            [NotificationResourceType.LayersCopy]: {
                                gotToMap: goToMap,
                                isUserCurrentlyInMap: (mapId) => currentMapId === mapId,
                            },
                        }}
                    />
                </PopoverErrorBoundary>
            </StyledPopover>
            <UserIconGenerator />
        </NavigationBarComponent>
    );
});

const VersionText = styled(Body3)`
    width: 100%;
    padding-top: 5px;
    text-align: center;
`;

const StyledUser = styled(UserThumbnail)`
    width: 2.5em;
    border: 1px solid transparent;
`;

const SubNavigation = styled.div`
    ${muiInputFormsCSS}
    display: flex;
    align-items: center;
    height: 100%;
    .help,
    .notifications,
    .user-profile {
        height: calc(100% + 20px);
        width: ${iconButtonSize};
    }
`;

const StyledPopover = styled(Popover)`
    .popover {
        display: flex;
        flex-direction: column;
        padding: 10px 20px;
        .menu-title {
            display: flex;
            gap: 5px;
            height: 44px;
            align-items: center;
            color: ${(props: { theme: Theme }) => props.theme.primaryColors.subdued70};
        }
        a {
            line-height: ${inputHeight};
            color: ${(props: { theme: Theme }) => props.theme.typographyColors.subdued};
            text-decoration-color: ${(props: { theme: Theme }) => props.theme.typographyColors.subdued};
            padding-right: 20px;
        }
        button {
            min-width: 85px;
        }
    }
`;

const PopoverErrorBoundary = styled(IventisErrorBoundaryWithToast)`
    width: auto;
`;

export default NavBarComponent;

const UserProfile: FunctionComponent = () => {
    const dispatch = useDispatch<RootDispatch>();

    const translate = useIventisTranslate();

    const apiUser = queryParams[API_KEY_QUERY_PARAM_KEY] != null; // If user has an api key, they cannot log out like a normal user.

    const switchLanguage = (e) => {
        i18n.changeLanguage(e.target.value).then(() => {
            window.location.reload();
        });
    };

    return (
        <div className="popover">
            <Button type="button" onClick={() => (apiUser ? logoutApiUser() : dispatch(logout()))} data-cy="logout-button">
                {translate(Content.login2.logout)}
            </Button>
            <HorizontalGap height="10px" />
            <Select
                value={i18n.language}
                onChange={switchLanguage}
                input={<StyledItemSelect $verticalPadding="8px" />}
                displayEmpty
                variant="outlined"
                inputProps={{ "aria-label": "Without label" }}
            >
                {languages.map((lng) => (
                    <MenuItem key={lng} value={lng}>
                        {lng.toUpperCase()}
                    </MenuItem>
                ))}
            </Select>
            <VersionText>{`V${VERSION}`}</VersionText>
        </div>
    );
};

const HelpAndResources: FunctionComponent = () => {
    const translate = useIventisTranslate();

    return (
        <div className="popover">
            <div className="menu-title">
                <FontAwesomeIcon icon="info-circle" />
                <Body1>{translate(Content.settings3.help_menu.title)}</Body1>
            </div>
            <Divider />
            <Link target="_blank" href="https://support.iventis.com/creating-your-first-plan">
                {translate(Content.settings3.help_menu.getting_started)}
            </Link>
            <Divider />
            <Link target="_blank" href="https://support.iventis.com/">
                {translate(Content.settings3.help_menu.knowledge_base)}
            </Link>
            <Divider />
            <Link target="_blank" href="https://support.iventis.com/kb-tickets/new">
                {translate(Content.settings3.help_menu.support)}
            </Link>
            <Divider />
            <Link target="_blank" href="https://www.iventis.com/about-us/">
                {translate(Content.settings3.help_menu.about)}
            </Link>
        </div>
    );
};
