/* eslint-disable no-console */
import { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { Content } from "@iventis/translations/content/typed-content";
import { FluentValidationError, MicroService, getFluentValidationErrors, isFluentValidationError } from "@iventis/api-helpers";
import { toast } from "@iventis/toasts/src/toast";
import { apis } from "@iventis/api/src/api";
import { removeUser } from "@iventis/permissions/src/auth.slice";
import { translate } from "@iventis/translations/translation";
import { Store } from "@iventis/plans/src/state/root.store";

type ErrorTypes = string | FluentValidationError;

const interceptor = (store: Store, microService: MicroService): void => {
    const API = apis[microService];

    API.interceptors.request.use(
        (conf: AxiosRequestConfig) =>
            // you can add some information before send it.
            // conf.headers['Auth'] = 'some token'.
            conf,
        (error: AxiosError) => {
            Promise.reject(error);
        }
    );
    API.interceptors.response.use(
        (next: AxiosResponse) => Promise.resolve(next),
        (error: AxiosError<ErrorTypes>) => {
            if (error.code === AxiosError.ERR_CANCELED || error.config.suppressErrors) {
                return;
            }
            if (error.config.suppressToasts) {
                // eslint-disable-next-line consistent-return
                return Promise.reject(error);
            }
            if (((error.response as unknown) as { aborted: boolean }).aborted) {
                return;
            }

            if (error.config?.customErrorMessage) {
                toast.error({ title: `${error.config.customErrorMessage.title}`, message: `${error.config.customErrorMessage.message}` });
                Promise.reject(error);
                return;
            }

            // You can handle error here and trigger warning message.
            const { response } = error;
            const { url } = error.config;

            // if the response is null and there is no internet connection
            if (response == null && !store.getState().connectionReducer.internet.isOnline) {
                toast.error({
                    title: translate(Content.errors.internet_connection_issue),
                    message: translate(Content.errors.internet_connection_issue),
                    overridingToastIcon: "triangle-exclamation",
                });
            }

            switch (response.status) {
                case 400:
                    {
                        let errorMessage: string;
                        if (isFluentValidationError(response.data)) {
                            errorMessage = getFluentValidationErrors(response.data, translate(Content.errors.problem_with_data_submitted));
                        } else {
                            // If error message is not a fluent validation error, show the error message unless it is null or an empty string
                            errorMessage = response.data != null && response.data !== "" ? response.data : translate(Content.errors.problem_with_data_submitted);
                        }
                        toast.error({
                            title: errorMessage,
                            message: errorMessage,
                            overridingToastIcon: "triangle-exclamation",
                        });
                    }
                    break;
                case 401:
                    if (!error.config.suppress401) {
                        store.dispatch(removeUser());
                        // Remove user from redux store. Will re-direct user to
                        // login as the state will change and cause a re-render.
                        if (
                            // Check the url does not include this route
                            // because it is called on the login screen when
                            // the user is not authorised. We dont want to handle this error.
                            !url.includes("/authentication/user") &&
                            !isFluentValidationError(response.data)
                        ) {
                            // Dispatch unauthorised message to global error handler.
                            console.error(`${response.data}`);
                        }
                    }
                    break;
                case 402:
                    // Ignore 402s, the webpage will handle that
                    break;
                case 500:
                    if (!isFluentValidationError(response.data)) {
                        console.error(`${response.data}: ${Content.errors.problem_with_our_servers_code}`);
                    }
                    break;
                case 503:
                    if (!isFluentValidationError(response.data)) {
                        console.error(`${response.data}: ${Content.errors.service_unavailable}`);
                    }
                    break;
                default:
                    if (error.name === "TimeoutError") {
                        console.error(`${Content.errors.timeout_connecting_servers}`);
                    }
            }

            // eslint-disable-next-line consistent-return
            return Promise.reject(error);
        }
    );
};

export default {
    interceptor,
};
